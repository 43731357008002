import { useEffect, useState } from "react";
import { useLocation,useParams } from "react-router-dom";
import PropTypes from "prop-types";
import MDBox from "../../../components/MDBox";;
import { useMaterialUIController, setLayout, resetStatusDetails, setPageLoading } from "../../../context";
import { handleIsFilterApplied, handleSelectedClaim, handleSelectedList, setIsClaimSelected, setLoadingClaim,  } from "../../../core/reducers/claims-reducer";
import { claimGetClaim, getClaimNotes } from "../../../core/actions/claims-actions";
import { claimGetTeamMembers } from "../../../core/actions/teamMembers-action";
import {claimsRoutes } from "../../../routes";
import { useAppDispatch } from "../../../core/store/hooks";
import { assignmentGetAssignments } from "../../../core/actions/assignments-actions";
import {  handleFilteredAssignment, startFiltering, } from "../../../core/reducers/assignments-reducer";
import {CircularProgress , Backdrop} from "@mui/material";
import { assignmentRequest } from "../../../core/models/assignments/constants";
import { handleSetMediaLoadingState } from "../../../core/reducers/media-reducer";
import { claimGetCustomTasks } from "../../../core/actions/customTasks-actions";
import { useMessage } from "../../../context/MessageProvider";
import { IDLE } from "../../../core/models/constants";
import { claimGetFlags, claimGetSelectedFlags } from "../../../core/actions/flags-actions";
import { useSelector } from "react-redux";
import { getSuperUserStatus, getUser, handleAuth } from "../../../core/reducers/auth-reducer";
import { authSuperUserStatus } from "../../../core/actions/auth-actions";
import { useOktaAuth } from '@okta/okta-react';

export function isCurrentUrlMatchingRoute(claimsRoutes, pathname) {
   
  const copiedClaimsRoutes  = [...claimsRoutes];
  for (const route of copiedClaimsRoutes) {
    const routePattern = route?.route.replace(/:[^/]+/g, '([^/]+)');
    const regexPattern = new RegExp(`^${routePattern}(/)*$`);
    if (regexPattern.test(pathname)) {
        return true; 
    }
  }
  return false;
}

export const debounce = (func, delay) => {
  let timeout;
  const debounced = (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };

  debounced.cancel = () => clearTimeout(timeout);

  return debounced;
};

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const appDispatch = useAppDispatch();
  const isSuperUser = useSelector(getUser);
  const { miniSidenav,pageLoading } = controller;
  const { pathname } = useLocation();
  const { showErrorMessage } = useMessage();
  const { id } = useParams();
  const isDashboard = pathname.endsWith('/dashboard');
  const isStatusPage = pathname.endsWith('/status');
  const isNotesPage = pathname.endsWith('/notes');
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {

    if (authState && authState?.isAuthenticated) {
      oktaAuth.getUser().then(info => {
        setUserInfo(info);
      
      });
    }
  
  }, [oktaAuth]);

  useEffect(() => {
    const fetchIsSuperUser = async () => {

    if (authState && authState.isAuthenticated) {
      !isSuperUser &&  await appDispatch(authSuperUserStatus({email : userInfo?.preferred_username}))
      await appDispatch(handleAuth(userInfo))
    }

  }
  userInfo && fetchIsSuperUser();
  }, [userInfo]);

{/* Need to refactor this code */}

useEffect(() => {
 
    const controller = new AbortController();
  
  
    const isClaimDashboardView = isCurrentUrlMatchingRoute(claimsRoutes, pathname);
    appDispatch(setIsClaimSelected(isClaimDashboardView));
  
    const fetchData = async (signal) => {
      try {
        setPageLoading(dispatch,true);
       if (isClaimDashboardView) {
          
          const customTasksResponse = await appDispatch(claimGetCustomTasks(), { signal });
          if (!customTasksResponse?.payload) {
            throw new Error('Failed to fetch custom tasks');
          }
        
          const claimResponse = await appDispatch(claimGetClaim(id), { signal });
          if (!claimResponse?.payload?.data) {
            throw new Error('Failed to fetch claim');
          }
  
          const claimNumber = claimResponse?.payload?.data?.claimNumber;
          const claimId = claimResponse?.payload?.data?.claimId;

          if(!isNotesPage && !isStatusPage)
          {
            const teamMembersResponse = await appDispatch(claimGetTeamMembers(claimNumber), { signal });
            if (!teamMembersResponse?.payload) {
              throw new Error('Failed to fetch team members');
            }
          }
          
          if(isDashboard && claimId)
            {
              const flagsListReponse = await appDispatch(claimGetFlags(null), { signal });
              if (!flagsListReponse?.payload) {
                throw new Error('Failed to fetch Flags');
              }
              const claimFlagsListReponse = await appDispatch(claimGetSelectedFlags(claimId), { signal });
              if (!claimFlagsListReponse?.payload) {
                throw new Error('Failed to fetch Flags for a claim');
              }
          }
          const requestPayload = { ...assignmentRequest, claimNumber };
          const assignmentResponse = await appDispatch(assignmentGetAssignments(requestPayload), { signal });
          if (!assignmentResponse?.payload?.data) {
            throw new Error('Failed to fetch assignments');
          }

          if(isDashboard) {
              const request = {
                pageNumber: 1,
                pageSize: 100,
                paginate: false,
              };
              const notesResponse = await appDispatch(getClaimNotes({ request, claimId: claimResponse?.payload?.data?.claimId }));
     
              if (!notesResponse?.payload?.data) {
                throw new Error('Failed to fetch Notes');
              }
          }
          const assignments = assignmentResponse.payload.data;
          await appDispatch(handleFilteredAssignment(assignments));
        } else {
          resetStatusDetails(dispatch, []);
          await appDispatch(handleSelectedClaim([]));
          await appDispatch(handleFilteredAssignment([]));
          await appDispatch(startFiltering(false));
          await appDispatch(setLoadingClaim(IDLE));
          await appDispatch(handleSetMediaLoadingState());
          await appDispatch(handleSelectedList({selectedAssignmentList: [], selectedNotesAddedByList: []}));
          await appDispatch(handleIsFilterApplied(false));
        }
      } catch (error) {
        showErrorMessage('An error occurred while fetching data.');
      }
      finally
      {
        setPageLoading(dispatch,false);

      }
    };
  
    const debouncedFetch = debounce(() => {
      const controller = new AbortController(); 
      const signal = controller.signal;

      fetchData(signal);
      return () => {
        controller.abort(); 
      };
    }, 500);

    debouncedFetch();
   setLayout(dispatch, 'dashboard');
  
    return () => {
      debouncedFetch.cancel();
      controller.abort();  
    };

  }, [pathname]);

  return (
    <div>
      {pageLoading &&  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      
      > <CircularProgress color="veriskBlue" size={80} /></Backdrop> }
      

       
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        pl: 0,
        position: "relative",
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(1),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
     
    </MDBox>
     
    </div>
  );
}


DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
